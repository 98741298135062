<template>
  <b-modal
    id="company-user-modal"
    ref="company-user-modal"
    :title="modalTitle"
    cancel-variant="outline-secondary"
    @show="fetchCompanyUsers"
    @hidden="resetModal"
  >
    <!-- Search -->
    <b-row>
      <b-col
        cols="12"
      >
        <b-form-input
          v-model="searchQuery"
          :placeholder="$t('SEARCH')"
        />
      </b-col>
    </b-row>
    <!-- Players  -->
    <div
      v-for="(user,index) in filteredUsers"
      :key="user.id"
      class="d-flex justify-content-start align-items-center"
      :class="index == 0 ? 'mt-2' : 'mt-1'"
    >
      <feather-icon
        icon="UserIcon"
        size="24"
        class="mr-50 text-success"
      />
      <div class="user-page-info">
        <h6 class="mb-0">
          {{ user.firstName }} {{ user.lastName }}
        </h6>
        <small class="text-muted">{{ user.email }}</small>
      </div>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="text-success"
        class="btn-icon ml-auto"
        size="sm"
      >
        <feather-icon
          icon="UserPlusIcon"
          class="text-success"
          @click="handleSubmit(user)"
        />
      </b-button>
    </div>
    <!--/ Players  -->

  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BButton, BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BModal, BButton, BRow, BCol, BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    company: {
      type: Object,
      default: () => {},
    },
    projectUserSave: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      users: [],
      filteredUsers: [],
      searchQuery: '',
    }
  },
  computed: {
    modalTitle() {
      const userCount = this.users ? this.users.length : 0
      const userText = userCount > 0
        ? `${userCount} ${this.$t('USERS_AVAILABLE')}`
        : this.$t('NO_USERS_AVAILABLE')
      return `${this.company.name} - ${userText}`
    },
  },
  watch: {
    searchQuery: {
      handler() {
        if (this.searchQuery) {
          const queryLowered = this.searchQuery.toLowerCase()
          this.filteredUsers = this.users.filter(
            user => (user.firstName.toLowerCase().includes(queryLowered)
            || user.lastName.toLowerCase().includes(queryLowered)),
          )
        }
      },
    },
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
    }
  },
  methods: {
    ...mapActions('team', ['createTeamUser']),
    ...mapActions('user', ['fetchUsersByCompanyID', 'fetchAllUsers']),
    async fetchUsers(data) {
      try {
        let response
        if (data.isAll) {
          response = await this.fetchAllUsers()
        } else {
          response = await this.fetchUsersByCompanyID(data.companyId)
        }
        if (response) {
          if (this.project.projectUsers.length > 0) {
            this.users = response.data.filter(user => !this.project.projectUsers.some(projectUser => projectUser.userId === user.id))
          } else {
            this.users = response.data
          }
          this.filteredUsers = this.users.slice(0, 10)
        } else {
          this.users = []
          this.filteredUsers = []
        }
      } catch (error) {
        // Handle any errors
        this.showErrorMessage()
      }
    },
    handleSubmit(data) {
      if (data.id) {
        this.projectUserSave({ id: data.id, projectId: this.project.id })
        this.resetModal()
        this.successMessage(this.$i18n.t('MESSAGE.USER_ADDED'))
      }
    },
    async fetchCompanyUsers() {
      // Ensure the company ID is present before fetching
      if (this.company && this.company.id) {
        const payload = {
          companyId: this.company.id,
          isAll: false,
        }
        try {
          await this.fetchUsers(payload)
        } catch (error) {
          this.showErrorMessage(this.$t('MESSAGE.ERROR_FETCHING_USERS'))
        }
      }
    },
    resetModal() {
      this.users = []
      this.filteredUsers = []
      this.searchQuery = ''
      this.$bvModal.hide('company-user-modal')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
