<template>
  <div>
    <div class="blog-detail-wrapper">
      <b-row>
        <!-- Project -->
        <b-col cols="12">
          <b-card>
            <div class="d-flex justify-content-between align-items-center">
              <!-- Card Title on the left -->
              <h5 class="mb-1">
                {{ project.name }}
              </h5>

              <!-- Edit Icon on the right -->
              <feather-icon
                v-show="isTodayOrFutureDate(project.end) && userData.userRoleId !== userRole.PLAYER"
                :id="`project-edit-${project.id}-send-icon`"
                icon="EditIcon"
                class="cursor-pointer"
                size="16"
                @click="$router.push({ name: 'project-configuration-id', params: { id: project.id }})"
              />
            </div>
            <b-media no-body>
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              >
                <feather-icon
                  icon="CalendarIcon"
                  size="24"
                  class="mr-50 text-primary"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted">{{ formatDate(project.start) }}</small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">{{ formatDate(project.end) }}</small>
              </b-media-body>
            </b-media>
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="blog-content  mt-2"
              v-html="project.description"
            />
            <!-- eslint-enable -->
            <hr class="my-2">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center mr-1">
                  <b-link class="mr-50">
                    <feather-icon
                      icon="UsersIcon"
                      size="21"
                      class="text-body"
                    />
                  </b-link>
                  <b-link>
                    <div class="text-body">
                      {{ project.projectUsers.length }}
                    </div>
                  </b-link>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
        <!--/ Project -->
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card
            no-body
          >
            <b-card-body>
              <b-tabs>
                <b-tab
                  :title="$t('USERS')"
                  active
                >
                  <b-card-text>
                    <b-row>
                      <b-col
                        md="12"
                      >
                        <b-card no-body>
                          <b-card-body>
                            <b-row class="mb-1">
                              <b-col
                                md="6"
                              >
                                <b-button
                                  variant="primary"
                                  class="ml-1"
                                  @click="openProjectUserModel()"
                                >
                                  <span>{{ $t('ADD_NEW_PROJECT_MEMBER') }} </span>
                                  <feather-icon icon="PlusCircleIcon" />
                                </b-button>
                                <b-button
                                  variant="primary"
                                  class="ml-1"
                                  @click="openCompanyUsersModel()"
                                >
                                  <span>{{ $t('ADD_PROJECT_MEMBER') }} </span>
                                  <feather-icon icon="PlusCircleIcon" />
                                </b-button>
                              </b-col>
                              <b-col
                                md="6"
                              >
                                <b-form-group label-for="id-categories">
                                  <v-select
                                    id="id-company"
                                    v-model="company"
                                    :placeholder="$t('SELECT_COMPANY')"
                                    label="name"
                                    :clearable="false"
                                    class="flex-grow-1"
                                    :options="allCompanies"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col lg="12">
                                <b-card-text class="mt-1">
                                  <span> {{ $t("PROJECT_PARTICIPANTS") }} </span>
                                </b-card-text>
                                <project-user-view
                                  :project-users="project.projectUsers"
                                  :delete-user="handleDeleteUser"
                                  :project-user-edit="openProjectUserModel"
                                />
                              </b-col>
                            </b-row>
                          </b-card-body>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-tab>
                <b-tab
                  :title="$t('Contents')"
                >
                  <b-card-text>

                    <b-button
                      variant="primary"
                      :to="{ name: 'content-configuration'}"
                      class="mb-2"
                    >
                      <span> {{ $t('ADD_CONTENT') }} </span>
                      <feather-icon icon="PlusCircleIcon" />
                    </b-button>
                    <company-content-view
                      :contents="allContents"
                    />
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <!-- Users -->
    <project-user-model
      :project-user-save="saveProjectUser"
      :project-user-edit="handleEditUser"
      :company="company"
      :edit-project-user="projectUser"
    />
    <company-user-model
      :project-user-save="saveProjectUser"
      :company="company"
      :project="project"
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormGroup,
  BCardText,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BButton,
  VBPopover,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
// eslint-disable-next-line import/no-cycle
import { formatDate, avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import { mixinList } from '@/constants/mixinValidations'
import { mapUserRoleToVariant, isTodayOrFutureDate } from '@/constants/utils'
import constants from '@/constants/static.json'
import useJwt from '@/auth/jwt/useJwt'
import ProjectUserView from './ProjectUserView.vue'
import ProjectUserModel from './ProjectUserModel.vue'
import CompanyUserModel from './CompanyUserModel.vue'
import CompanyContentView from '../../company/company-list/CompanyContentView.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BLink,
    BFormGroup,
    BMedia,
    BMediaAside,
    BMediaBody,
    vSelect,
    BButton,
    ProjectUserView,
    ProjectUserModel,
    CompanyUserModel,
    CompanyContentView,
    // eslint-disable-next-line vue/no-unused-components
    VBPopover,
    BTabs,
    BTab,
  },
  // eslint-disable-next-line no-dupe-keys
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [mixinList],
  data() {
    return {
      project: {
        id: 0,
        name: '',
        start: '',
        end: '',
        companyId: 0,
        description: '',
        projectUsers: [],
        contents: [],
      },
      userData: useJwt.getUser() || {},
      userRole: constants.USER_ROLE,
      maxUserId: 0,
      company: {
        id: 0,
        name: '',
      },
      projectUser: {},
      allCompanies: [],
      contentTypeIds: constants.CONTENT_TYPE,
    }
  },
  setup() {
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
      formatDate,
      mapUserRoleToVariant,
      isTodayOrFutureDate,
      avatarText,
    }
  },
  computed: {
    ...mapState('content', ['allContents']),
  },
  async created() {
    if (router.currentRoute.params.id) {
      // eslint-disable-next-line radix
      this.project.id = parseInt(router.currentRoute.params.id)
      if (this.project.id !== 0) {
        this.fetchProjectByID(this.project.id).then(async response => {
          if (response) {
            this.project = response.data
            this.maxUserId = this.getMaxUserId(this.project)
            await this.fetchCompany()
            this.fetchContentsByProjectID(this.project.id)
          }
        })
      }
    }
  },
  methods: {
    ...mapActions('company', ['fetchAllCompanies']),
    ...mapActions('content', ['fetchContentsByProjectID']),
    ...mapActions('project', [
      'createProjectUser',
      'updateProjectUser',
      'deleteProjectUser',
      'fetchProjectByID',
    ]),
    reloadData() {
      this.fetchProjectByID(this.project.id).then(async response => {
        if (response) {
          this.project = response.data
        }
      })
    },
    openProjectUserModel(data) {
      if (this.company) {
        this.company = { ...this.company }
      }
      if (data) {
        this.projectUser = { ...data }
      }
      this.$bvModal.show('project-user-modal')
    },
    openCompanyUsersModel() {
      if (this.company) {
        this.company = { ...this.company }
      }
      this.$bvModal.show('company-user-modal')
    },
    async fetchCompany() {
      this.fetchAllCompanies().then(response => {
        if (response) {
          this.allCompanies = response.data
          if (this.project.id) {
            this.company = this.allCompanies.find(
              i => i.id === this.project.companyId,
            )
          } else if (this.allCompanies.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.company = this.allCompanies[0]
          }
        }
      })
    },
    getMaxUserId(data) {
      const { projectUsers } = data

      // Check if the array is not empty
      if (projectUsers && projectUsers.length > 0) {
        // Extract an array of question IDs
        const userIds = projectUsers.map(user => user.id)

        // Find the maximum ID
        return Math.max(...userIds)
      }
      // If survey_questions is empty, return a default value (e.g., 1)
      return 0
    },
    saveProjectUser(user) {
      const payload = {
        userId: user.id,
        isProjectAdmin: user?.isProjectAdmin,
        projectId: this.project.id,
      }
      this.createProjectUser(payload).then(async response => {
        if (response) {
          this.reloadData()
        }
      }).catch(() => {
        this.showErrorMessage()
      })
    },
    handleDeleteUser(userToRemove) {
      // eslint-disable-next-line no-param-reassign
      const indexToRemove = this.project.projectUsers.findIndex(
        user => user.userId === userToRemove.userId,
      )
      if (indexToRemove !== -1) {
        this.project.projectUsers.splice(indexToRemove, 1)
        this.deleteProjectUser(userToRemove)
      }
    },
    async handleEditUser(user) {
      await this.updateProjectUser(user)
      await this.reloadData()
    },
  },
}
</script>

<style lang="scss" scoped>
.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
