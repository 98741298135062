<template>
  <b-card
    v-if="contents"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="paginatedContents"
      responsive
      :fields="tableColumns"
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      primary-key="id"
      class="mb-0"
    >

      <!-- Column:Name -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              :id="`content-row-${data.item.id}`"
              size="32"
              :variant="mapToContentTypeIcon(data.item.contentTypeId).variant"
              :to="{ name: 'content-view', params: { id: data.item.id } }"
            >
              <feather-icon
                :icon="mapToContentTypeIcon(data.item.contentTypeId).icon"
              />
            </b-avatar>
          </template>
          <b-link
            :to="{ name: 'content-view', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ strSubstring(data.item.name,20) }}
          </b-link>
          <small class="text-muted"> <b-badge
            pill
            :variant="`${mapFrequencyToVariant(data.item.contentFrequency.name)}`"
            class="text-capitalize small"
          >
            {{ data.item.contentType.name }}
          </b-badge>
          </small>
          <small class="text-muted"> <b-badge
            pill
            :variant="`${mapFrequencyToVariant(data.item.contentFrequency.name)}`"
            class="text-capitalize small"
          >
            {{ data.item.contentFrequency.name }}
          </b-badge>
          </small>
        </b-media>
      </template>
      <!-- Column:Category -->
      <template #cell(category)="data">
        <div class="d-flex align-items-center">
          <!-- Avatar with Dynamic Variant Color -->
          <b-avatar
            class="mr-1"
            :variant="mapTagToVariant(data.item.tag).variant"
          >
            <!-- Feather Icon inside the Avatar -->
            <feather-icon
              size="18"
              :icon="mapTagToVariant(data.item.tag).Icon"
            />
          </b-avatar>
          <!-- Display Tag or Fallback to 'Other' -->
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.tag !== null ? data.item.tag : 'Other' }}
          </span>
        </div>
      </template>
      <!-- Column:StartDate -->
      <template #cell(start)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.start) }}
          </span>
        </b-media>
      </template>
      <!-- Column:EndDate -->
      <template #cell(end)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.end) }}
          </span>
        </b-media>
      </template>

      <!-- Column:Status -->
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`${mapStatusToVariant(data.item.contentStatus.name)}`"
          class="text-capitalize small"
        >
          {{ data.item.contentStatus.name }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            v-show="isTodayOrFutureDate(data.item.end)"
            :id="`content-edit-${data.item.id}-send-icon`"
            icon="EditIcon"
            size="16"
            class="cursor-pointer"
            @click="$router.push({ name: 'content-configuration-id', params: { id: data.item.id }})"
          />
          <feather-icon
            :id="`content-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="$router.push({ name: 'content-view', params: { id: data.item.id }})"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalContents"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BPagination,
  BRow,
  BCol,
  BAvatar,
  BLink,
  BMedia,
  BBadge,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
import { mixinList } from '@/constants/mixinValidations'
import constants from '@/constants/static.json'
import {
  mapTagToVariant,
  mapStatusToVariant,
  mapFrequencyToVariant,
  mapToContentTypeIcon,
  isTodayOrFutureDate,
} from '@/constants/utils'

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BAvatar,
    BLink,
    BMedia,
    BBadge,
  },
  mixins: [mixinDate, mixinList],
  props: {
    contents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortBy: 'id',
      perPage: constants.PER_PAGE,
      perPageOptions: constants.PER_PAGE_OPTIONS,
      currentPage: 1,
      tableColumns: [
        { key: 'name', label: 'Name', sortable: false },
        { key: 'category', label: 'Category', sortable: false },
        { key: 'start', label: 'Start Date', sortable: false },
        { key: 'end', label: 'End Date', sortable: false },
        { key: 'status', label: 'Status', sortable: false },
        { key: 'Actions' },
      ],
    }
  },
  computed: {
    totalContents() {
      return this.contents.length
    },
    dataMeta() {
      const localItemsCount = this.contents.length
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalContents,
      }
    },
    paginatedContents() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.contents.slice(start, end)
    },
  },
  setup() {
    return {
      mapTagToVariant,
      mapStatusToVariant,
      mapFrequencyToVariant,
      mapToContentTypeIcon,
      isTodayOrFutureDate,
    }
  },

}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
